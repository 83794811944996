<template>
    <div id="steps-explanation">
        <section class="steps-explanation-section mb-5">
            <div class="container text-center">
                <div class="row justify-content-center">
                    <div class="col">
                        <h1 class="home-title mb-md-5">How it works</h1>
                    </div>
                </div>
                <perfect-local-match></perfect-local-match>
                <choose-your-vision></choose-your-vision>
                <personalize-your-ring></personalize-your-ring>
                <view-pay-pickup></view-pay-pickup>
            </div>
        </section>
    </div>
</template>

<script>
import PerfectLocalMatch from "./PerfectLocalMatch.vue";
import ChooseYourVision from "./ChooseYourVision.vue";
import PersonalizeYourRing from "./PersonalizeYourRing.vue";
import ViewPayPickup from "./ViewPayPickup.vue";

export default {
    name: "steps-explanation",
    components: {
        "perfect-local-match": PerfectLocalMatch,
        "choose-your-vision": ChooseYourVision,
        "personalize-your-ring": PersonalizeYourRing,
        "view-pay-pickup": ViewPayPickup,
    },
};
</script>

<style lang="scss" scoped>
#steps-explanation {
    min-height: 929px;
    background-image: linear-gradient(153deg, rgba(255, 218, 224, 0.36) 6%, rgba(255, 255, 255, 0.36) 40%);
    .steps-explanation-section {
        padding-top: 80px;
        padding-bottom: 40px;
    }
    @media (max-width: 767px) {
        .steps-explanation-section {
            padding-top: 20px;
            padding-bottom: 0px;
        }
    }
}
</style>
