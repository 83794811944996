<template>
    <div id="mobile-title" class="d-lg-none">
        <section class="title-section">
            <div class="container text-center">
                <div class="row justify-content-center">
                    <div class="col-md-12">
                        <h1 class="home-title">Match With the Perfect Jeweler</h1>
                        <h6 class="home-subtitle text-uppercase mb-4">Discover, Compare, Choose - Your Dream Engagement Ring Awaits!</h6>
                        <button v-if="isHome" aria-label="find a jeweler" class="btn rnd-primary-btn text-uppercase" @click="toFindJeweler">Start Now</button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "mobile-title",
    computed: {
        ...mapGetters("willyou", ["findJewelerLink"]),
        isHome() {
            return this.$route.name === "home";
        },
    },
    methods: {
        toFindJeweler() {
            this.$router.push(this.findJewelerLink);
        },
    },
};
</script>

<style lang="scss" scoped>
#mobile-title {
    .title-section {
        padding-top: 40px;
        padding-bottom: 20px;
    }
}
</style>
