<template>
    <div id="perfect-local-match">
        <div class="row justify-content-center d-none d-lg-block">
            <div class="col-4">
                <div class="little-diamonds-area">
                    <img
                        data-aos="fade-up"
                        data-aos-duration="1200"
                        v-for="i in 3"
                        :id="`little-diamond-${i}`"
                        :key="i"
                        src="../../../../../assets/images/home/little-diamond.svg"
                        class="animated infinite slow"
                        :class="{ bounce: i === 1, tada: i === 2, wobble: i === 3 }" />
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg col-md-12 order-lg-last pr-lg-0 pl-lg-0">
                <div class="container pr-lg-0 pl-lg-0">
                    <div class="row">
                        <div class="col-lg-2 col-12 pr-lg-0 pl-lg-0">
                            <h1 class="w-100 home-title text-lg-center text-center go-over-left float-lg-right float-none">01</h1>
                        </div>
                        <div class="col-lg col-12 pt-3">
                            <h5 class="noto-sans-kr text-lg-left text-center">Search by Location</h5>
                            <p class="noto-sans-font-title text-lg-left text-center steps-p">
                                Locate your city or state to find top-rated local jewelers specializing in diamond engagement rings.
                            </p>
                        </div>
                    </div>
                    <div class="w-100"></div>
                    <div class="col-lg col-6 left-border float-right"></div>
                </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="1200" class="col-lg col-12 pr-lg-0 pl-lg-0">
                <img-webp
                    classs="mx-auto img-fluid steps-img-md"
                    alt="Select a jewelry store near you on a laptop google map"
                    src="/images/home/local-match.png"></img-webp>
            </div>
        </div>
    </div>
</template>

<script>
import ImgWebp from "../../../../components/ImgWebp";

export default {
    name: "perfect-local-match",
    components: {
        "img-webp": ImgWebp,
    },
};
</script>

<style lang="scss" scoped>
#perfect-local-match {
    .left-border {
        border-left: 1px solid #000000;
        height: 46px;
    }
    .go-over-left {
        left: calc(15px - 50%);
        position: absolute;
    }
    .steps-p {
        padding-bottom: 0;
        min-height: 84px;
    }
    .little-diamonds-area {
        height: 150px;
        margin-bottom: 20px;
        img {
            position: absolute;
        }
        #little-diamond-1 {
            bottom: 20px;
            right: 160px;
            transform: rotate(-34deg);
        }
        #little-diamond-2 {
            bottom: 0;
            right: 80px;
            transform: rotate(-14deg);
        }
        #little-diamond-3 {
            top: 0;
            right: 0;
        }
    }
    @media (max-width: 991px) {
        .left-border,
        .right-border {
            border-width: 2px;
            height: 76px;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 60px;
        }

        .go-over-right,
        .go-over-left {
            margin-right: 0 !important;
            margin-left: 0 !important;
            left: 0;
            right: 0;
            position: relative;
        }
    }
}
</style>
