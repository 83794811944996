<template>
    <div id="view-pay-pickup">
        <div class="row justify-content-center">
            <div class="col-lg col-12 pr-lg-0 pl-lg-0 margin-top-130-px">
                <div class="container pr-lg-0 pl-lg-0">
                    <div class="row">
                        <div class="col-lg-2 col-12 order-lg-last pr-lg-0 pl-lg-0">
                            <h1 class="w-100 home-title text-lg-center text-md-center go-over-right float-lg-left float-none">04</h1>
                        </div>
                        <div class="col-lg col-12 pr-lg-0 pl-lg-0pt-3">
                            <h5 class="noto-sans-kr text-lg-right text-center">Connect and Create</h5>
                            <p class="noto-sans-font-title text-lg-right text-center steps-p mw-280-px float-lg-right float-none">
                                Get in touch with your selected jeweler and start the process of designing your perfect diamond engagement ring.
                            </p>
                        </div>
                    </div>
                    <div class="w-100"></div>
                    <div class="col-lg col-6 right-border float-left"></div>
                </div>
            </div>
            <div class="col-lg col-12 order-lg-last pr-lg-0 pl-lg-0">
                <img-webp
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    classs="mx-auto img-fluid steps-img-md"
                    alt="View, Pay & Pickup"
                    src="/images/home/view-and-pickup.png"></img-webp>
                <img-webp
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-duration="1200"
                    classs="d-none d-xl-block add-on-image"
                    alt="Couple engagement ring in a jewelry store"
                    src="/images/home/view-and-pickup-2.png"></img-webp>
            </div>
        </div>
    </div>
</template>

<script>
import ImgWebp from "../../../../components/ImgWebp";

export default {
    name: "view-pay-pickup",
    components: {
        "img-webp": ImgWebp,
    },
};
</script>

<style lang="scss" scoped>
#view-pay-pickup {
    .right-border {
        border-right: 1px solid #000000;
        height: 46px;
    }
    .go-over-right {
        right: calc(15px - 50%);
        position: absolute;
    }

    .steps-p {
        padding-bottom: 0;
        min-height: 84px;
    }
    .add-on-image {
        position: absolute;
        top: 260px;
        left: 260px;
    }
    @media (max-width: 991px) {
        .left-border,
        .right-border {
            border-width: 2px;
            height: 76px;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 60px;
        }

        .go-over-right,
        .go-over-left {
            margin-right: 0 !important;
            margin-left: 0 !important;
            left: 0;
            right: 0;
            position: relative;
        }
    }
}
</style>
