<template>
    <div id="home">
        <hero></hero>
        <mobile-title></mobile-title>
        <how-it-works></how-it-works>
        <steps-explanation></steps-explanation>
        <design-your-own></design-your-own>
        <div class="col-12">
            <popular-posts></popular-posts>
        </div>
        <jeweler-ai-banner></jeweler-ai-banner>
        <find-jeweler></find-jeweler>
        <router-view></router-view>
        <successfully-loaded></successfully-loaded>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Hero from "./sections/Hero.vue";
import HowItWorks from "./sections/HowItWorks.vue";
import MobileTitle from "./sections/MobileTitle.vue";
import DesignYourOwn from "./sections/DesignYourOwn.vue";
import PopularPosts from "../../components/LatestPosts";
import StepsExplanation from "./sections/steps-explanation/StepsExplanation.vue";
import FindJeweler from "../../components/store-locator/FindJeweler.vue";
import SuccessfullyLoaded from "../../components/SuccessfullyLoaded.vue";
import JewelerAiBanner from "../../components/JewelerAiBanner.vue";

export default {
    name: `home`,
    components: {
        hero: Hero,
        "how-it-works": HowItWorks,
        "mobile-title": MobileTitle,
        "design-your-own": DesignYourOwn,
        "popular-posts": PopularPosts,
        "steps-explanation": StepsExplanation,
        "find-jeweler": FindJeweler,
        "successfully-loaded": SuccessfullyLoaded,
        "jeweler-ai-banner": JewelerAiBanner,
    },
    computed: {
        ...mapGetters("willyou", ["getCurrentMeta"]),
    },
    methods: {
        ...mapActions("willyou", ["removeStoreData"]),
    },
    mounted() {
        this.removeStoreData();
    },
    metaInfo() {
        return this.getCurrentMeta;
    },
};
</script>

<style lang="scss" scoped>
#home {
    min-height: 100vh;
}
</style>
