<template>
    <div id="personalize-your-ring">
        <div class="row justify-content-center">
            <div class="col-lg col-md-12 order-lg-last pr-lg-0 pl-lg-0 margin-top-100-px">
                <div class="container pr-lg-0 pl-lg-0">
                    <div class="row">
                        <div class="col-lg-2 col-12 pr-lg-0 pl-lg-0">
                            <h1 class="w-100 home-title text-lg-center text-center go-over-left float-lg-right float-none">03</h1>
                        </div>
                        <div class="col-lg col-12 pt-3">
                            <h5 class="noto-sans-kr text-lg-left text-center">Compare Ratings</h5>
                            <p class="noto-sans-font-title text-lg-left text-center steps-p mw-280-px float-lg-left float-none">
                                Our clear, trustworthy jeweler ratings make it easy to compare and choose the best jeweler for your needs.
                            </p>
                        </div>
                    </div>
                    <div class="w-100"></div>
                    <div class="col-lg col-6 left-border float-right"></div>
                </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="1200" class="col-lg col-12 pr-lg-0 pl-lg-0">
                <img-webp
                    classs="mx-auto img-fluid steps-img-md"
                    alt="Design of engagement ring and a diamond ring"
                    src="/images/home/personalize-your-ring.png"></img-webp>
            </div>
        </div>
    </div>
</template>

<script>
import ImgWebp from "../../../../components/ImgWebp";

export default {
    name: "personalize-your-ring",
    components: {
        "img-webp": ImgWebp,
    },
};
</script>

<style lang="scss" scoped>
#steps-explanation {
    min-height: 929px;
    background-image: linear-gradient(153deg, rgba(255, 218, 224, 0.36) 6%, rgba(255, 255, 255, 0.36) 40%);
    .steps-explanation-section {
        padding-top: 80px;
        padding-bottom: 40px;
    }

    .left-border {
        border-left: 1px solid #000000;
        height: 46px;
    }
    .right-border {
        border-right: 1px solid #000000;
        height: 46px;
    }
    .go-over-left {
        left: calc(15px - 50%);
        position: absolute;
    }
    .go-over-right {
        right: calc(15px - 50%);
        position: absolute;
    }
    .steps-p {
        padding-bottom: 0;
        min-height: 84px;
    }
    .add-on-image {
        position: absolute;
        top: 260px;
        left: 260px;
    }
    .diamond-img {
        width: 240px;
        position: absolute;
        top: 460px;
        left: 460px;
    }
    .little-diamonds-area {
        height: 150px;
        margin-bottom: 20px;
        img {
            position: absolute;
        }
        #little-diamond-1 {
            bottom: 20px;
            right: 160px;
            transform: rotate(-34deg);
        }
        #little-diamond-2 {
            bottom: 0;
            right: 80px;
            transform: rotate(-14deg);
        }
        #little-diamond-3 {
            top: 0;
            right: 0;
        }
    }
    @media (max-width: 767px) {
        .steps-explanation-section {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
    @media (max-width: 991px) {
        .left-border,
        .right-border {
            border-width: 2px;
            height: 76px;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 60px;
        }
        .go-over-right,
        .go-over-left {
            margin-right: 0 !important;
            margin-left: 0 !important;
            left: 0;
            right: 0;
            position: relative;
        }
    }
    .img-floating-text {
        text-shadow: 0 0 14px rgba(0, 0, 0, 0.5);
        font-family: "Satisfy", cursive;
        font-size: 30px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 45%;
        margin: auto;
        z-index: 1;
    }
}
</style>
