<template>
    <div id="home-hero">
        <section class="hero-banner" :class="{ 'hero-banner-store': isStore, 'hero-banner-home': !isStore }">
            <div class="container-fluid d-none d-lg-block">
                <div class="row">
                    <div class="col-md-5">
                        <h1 class="home-title">Match With the Perfect Jeweler</h1>
                        <h6 class="home-subtitle text-uppercase mb-4">Discover, Compare, Choose - Your Dream Engagement Ring Awaits!</h6>
                        <button v-if="!isStore" aria-label="find a jeweler" class="btn rnd-primary-btn text-uppercase" @click="toFindJeweler">Start Now</button>
                    </div>
                </div>
            </div>
            <div v-if="!isStore">
                <img-webp
                    classs="hero-img d-none d-md-block d-lg-none"
                    src="/images/home/hero-tablet.png"
                    alt="Solitaire engagement ring held by guy asking girl to marry him">
                </img-webp>
                <img-webp classs="hero-img d-md-none" src="/images/home/hero-mobile.png" alt="Solitaire engagement ring held by guy asking girl to marry him">
                </img-webp>
            </div>
            <div v-if="isStore">
                <img-webp
                    classss="hero-img d-none d-md-block d-lg-none"
                    src="/images/store/hero-store-tablet.png"
                    alt="Solitaire engagement ring held by guy asking girl to marry him"></img-webp>
                <img-webp
                    classss="hero-img d-md-none"
                    src="/images/store/hero-store-mobile.png"
                    alt="Solitaire engagement ring held by guy asking girl to marry him"></img-webp>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ImgWebp from "../../../components/ImgWebp";

export default {
    name: "home-hero",
    components: { "img-webp": ImgWebp },
    computed: {
        ...mapGetters("willyou", ["findJewelerLink"]),
        isStore() {
            return this.$route.name === "home-store";
        },
    },
    methods: {
        toFindJeweler() {
            this.$router.push(this.findJewelerLink);
        },
    },
};
</script>

<style lang="scss">
#home-hero {
    margin-top: 40px;
    .hero-banner {
        position: relative;
        width: 100%;
        .hero-img {
            width: 100%;
            height: auto;
        }
    }
    @media (min-width: 992px) {
        margin-top: 80px;
        .hero-banner-home {
            height: 680px;
            padding: 200px 100px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top center;
        }
        .hero-banner-store {
            height: 680px;
            padding: 200px 100px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top center;
        }
    }
}
@media (min-width: 992px) {
    .webp .hero-banner-home {
        background: url("../../../../assets/images/home/hero-desktop.png.webp");
    }
    .no-webp .hero-banner-home {
        background: url("../../../../assets/images/home/hero-desktop.png");
    }
    .webp .hero-banner-store {
        background: url("../../../../assets/images/store/hero-store-desktop.png.webp");
    }
    .no-webp .hero-banner-store {
        background: url("../../../../assets/images/store/hero-store-desktop.png");
    }
}
</style>
