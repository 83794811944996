<template>
    <div id="find-jeweler">
        <section class="find-jeweler-section md-5" :class="{ 'find-jeweler-hero': isHero }">
            <div class="container text-center md-5">
                <div class="row justify-content-center flex-column">
                    <div class="col">
                        <component :is="getComponentType" class="home-title text-center">Jewelry Store Near You</component>
                        <p class="noto-sans-font-title text-center text-uppercase">Locate the closest jewelry store and get started with your ring today!</p>
                    </div>
                    <form @submit.prevent="search">
                        <div class="col input-zip m-auto">
                            <div class="input-group mb-3">
                                <input
                                    v-model="query"
                                    type="text"
                                    class="form-control rnd-input-text"
                                    placeholder="Enter ZIP Code"
                                    aria-label="enter ZIP Code"
                                    aria-describedby="enter ZIP Code" />
                                <div class="input-group-append">
                                    <button aria-label="search" class="btn rnd-primary-btn" @click="search">SEARCH</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div v-if="!isHero" class="row justify-content-center flex-column">
                    <div class="col">
                        <router-link :to="{ name: 'find-a-jeweler-by-state' }" class="noto-sans-font-title text-center browse-all-link">Browse All</router-link>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "find-jeweler",
    data() {
        return {
            query: "",
        };
    },
    computed: {
        isHero() {
            return this.$route.name === "find-a-jeweler-by-state" || this.$route.name === "find-a-jeweler-by-city";
        },
        getComponentType() {
            return "h2";
        },
    },
    methods: {
        search() {
            this.query
                ? this.$router.push({
                      path: `/jewelry-stores/results?zipcode=${this.query}`,
                  })
                : null;
        },
    },
};
</script>

<style lang="scss" scoped>
#find-jeweler {
    padding-top: 20px;
    min-height: 424px;
    background: url("../../../assets/images/backgrounds/generic-background-desktop.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    .find-jeweler-section {
        padding-top: 64px;
    }
    .find-jeweler-hero {
        padding-top: 120px;
    }
    .input-zip {
        max-width: 510px;
    }
    .browse-all-link {
        font-size: 14px;
    }
    @media (max-width: 767px) {
        .find-jeweler-section {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        .find-jeweler-hero {
            padding-top: 100px;
        }
    }
}
</style>
