<template>
    <div id="how-it-works">
        <section class="how-it-works-section">
            <div class="container text-center">
                <div class="row justify-content-center">
                    <div class="col-lg">
                        <div v-if="showNumbers" class="text-center home-title">1</div>
                        <div class="noto-sans-kr">{{ getHeadOne }}</div>
                        <p class="noto-sans-font-title">
                            {{ getTextOne }}
                        </p>
                    </div>
                    <div class="col-lg">
                        <div v-if="showNumbers" class="text-center home-title">2</div>
                        <div class="noto-sans-kr">{{ getHeadTwo }}</div>
                        <p class="noto-sans-font-title">{{ getTextTwo }}</p>
                    </div>
                    <div class="col-lg">
                        <div v-if="showNumbers" class="text-center home-title">3</div>
                        <div class="noto-sans-kr">{{ getHeadThree }}</div>
                        <p class="noto-sans-font-title">{{ getTextThree }}</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
    name: "how-it-works",
    props: ["showNumbers"],
    computed: {
        ...mapState("willyou", ["currentStore"]),
        ...mapGetters("willyou", ["getSemiAddress"]),

        getLocationPageLinks() {
            if (!this.showLink) return null;

            let currentLocation = this.getSemiAddress.split(", ");
            let link = `/jewelry-stores/${currentLocation[1].toLowerCase()}/${currentLocation[0].toLowerCase().trim().replace(/\s/g, "-")}`;
            return link;
        },
        showLink() {
            return typeof this.currentStore === "object" && this.currentStore !== null && Object.keys(this.currentStore).length > 0;
        },
        isStorePage() {
            return this.currentStore && this.currentStore.companyName;
        },
        getStoreName() {
            return this.isStorePage ? this.currentStore.companyName : "";
        },
        getHeadOne() {
            return this.isStorePage ? "Review the Jeweler's Details" : "+2,000,000 Feedbacks Analyzed";
        },
        getHeadTwo() {
            return this.isStorePage ? "Contact the Jeweler Directly" : "Nationwide Jeweler Network";
        },
        getHeadThree() {
            return this.isStorePage ? "Start Creating Your Ring" : "Find Your Perfect Jeweler";
        },
        getTextOne() {
            return this.isStorePage
                ? `Explore the jeweler's expertise, customer feedback, and available inventory to ensure they match your preferences.`
                : `With over 2,000,000 feedbacks reviewed and counting, we provide clear, reliable ratings to help you choose the best jeweler for your engagement ring.`;
        },
        getTextTwo() {
            return this.isStorePage
                ? `Use the provided contact information to reach out and discuss your engagement ring vision, confirming the next steps.`
                : `With connections to local jewelers across the USA, we offer insider insights on community trends and provide detailed jeweler profiles.`;
        },
        getTextThree() {
            return this.isStorePage
                ? `Collaborate with the jeweler to choose your diamond and setting, and begin designing the perfect engagement ring tailored to your style.`
                : `We simplify your search for the ideal jeweler to create your dream diamond engagement ring with ease and confidence.`;
        },
    },
};
</script>

<style lang="scss" scoped>
#how-it-works {
    .noto-sans-kr {
        text-transform: uppercase;
    }
    .how-it-works-section {
        padding-top: 80px;
        padding-bottom: 40px;
        .container {
            max-width: 1260px;
        }
    }
    .link {
        text-decoration: underline;
    }
    @media (max-width: 767px) {
        .how-it-works-section {
            padding-top: 20px;
            padding-bottom: 0;
        }
    }
}
</style>
