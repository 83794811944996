<template>
    <div id="design-your-own">
        <section class="how-it-works-section">
            <div class="container text-center">
                <div class="row justify-content-center align-self-center">
                    <div class="offset-lg-1 col-lg-5 col-md-12">
                        <img-webp
                            classs="mx-md-auto ml-lg-auto d-block img-fluid design-your-own-img"
                            alt="design yout own ring"
                            src="/images/home/design-your-own-ring.png"></img-webp>
                        <span class="img-floating-text">Start your forever</span>
                        <div class="d-none d-lg-block">
                            <img-webp
                                classs="mx-auto img-fluid floating-ring-img desktop-element"
                                alt="design your own ring"
                                src="/images/home/float-ring.png"></img-webp>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-12">
                        <div class="box h-100 d-lg-flex justify-content-center flex-column text-lg-left text-md-center">
                            <h5 class="home-title">Find Your Jeweler</h5>
                            <h6 class="home-subtitle mb-4 fs-15">Start designing your dream engagement ring with a trusted local expert.</h6>
                            <button aria-label="start with a ring" class="btn rnd-secondary-btn balance-width text-uppercase" @click="toFindJeweler">
                                Get Started
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ImgWebp from "../../../components/ImgWebp";

export default {
    name: "design-your-own",
    components: {
        "img-webp": ImgWebp,
    },
    computed: {
        ...mapGetters("willyou", ["startWithRingLink"]),
    },
    methods: {
        toFindJeweler() {
            this.$router.push(this.startWithRingLink);
        },
    },
};
</script>

<style lang="scss" scoped>
#design-your-own {
    padding-bottom: 6.5rem;

    button.balance-width {
        width: max-content;
    }
    .img-floating-text {
        text-shadow: 0 0 14px rgba(0, 0, 0, 0.5);
        font-family: "Satisfy", cursive;
        font-size: 30px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 80px;
        margin: auto;
        z-index: 1;
    }
    @media (min-width: 768px) {
        .floating-ring-img {
            position: absolute;
            z-index: 1;
            left: -50px;
            bottom: -135px;
            height: 250px;
        }
        .how-it-works-section {
            padding-bottom: 150px;
        }
    }
    @media (max-width: 991px) {
        .how-it-works-section {
            padding-bottom: 40px;
        }
    }
}
</style>
